.patient {
  background-color: #f9fbfd;
  padding-top: calc(1.5rem + 56px);
  min-height: 100vh;
  margin-top: 1.5rem;
  .xs-menu {
    svg {
      height: 1.2rem;
      fill: $primary;
    }
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-color: #edf2f9;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    border-radius: 1rem;
    overflow: hidden;

    .card-header {
      padding: 1rem 1.25rem;
      margin-bottom: 0;
      background-color: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      &:first-child {
        border-radius: calc(1rem - 1px) calc(1rem - 1px) 0 0;
      }
    }
    .card-footer {
      &:last-child {
        background-color: #f9fbfd;
        border-top-color: #edf2f9;
        border-radius: 0 0 calc(1rem - 1px) calc(1rem - 1px);
      }
    }
  }
  .avatar {
    height: 3rem;
    width: 3rem;
    position: relative;
    display: inline-block;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .avatar-xs {
    height: 2.1875rem;
    width: 2.1875rem;
  }
  .avatar-lg {
    height: 4rem;
    width: 4rem;
  }
  .nav.flex-column:not(.nav-pills) .nav-link {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .nav-link {
    font-weight: 500 !important;
    font-size: 0.9rem;
  }

  .nav-link-secondary .nav-item .nav-link {
    color: #14191e;
  }

  textarea.form-control {
    min-height: calc(1.5em + 1rem + 2px);
    &:focus {
      box-shadow: none !important;
    }
  }

  [data-autoresize] {
    resize: none;
  }
  .nav.nav-divider .nav-item + .nav-item:before {
    content: '•';
    color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    opacity: 0.8;
  }
  #formFileSm {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    & + label {
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      margin: 0;
      svg {
        width: 1em;
        height: 1em;
        vertical-align: middle;
        margin-right: 0.25em;
      }
      span {
        color: $black;
      }
    }
  }
  .icon-md {
    padding: 0.5rem;
    font-size: 0.9em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 1rem;
      width: 1rem;
    }
  }
  .nav-link svg {
    margin-right: 0.25em;
  }
  .nav-link {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      color: $primary !important;
    }
  }
  .subscriber-list {
    .card-body {
      max-height: 19rem;
      overflow-y: auto;
    }
  }
  @media screen and (max-width: 600px) {
    .xs-tab {
      display: none;
      &.active-tab {
        display: flex !important;
        padding-bottom: 4rem;
      }
    }
  }
}
