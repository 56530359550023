.steps-0,
.steps-1,
.steps-2,
.steps-3,
.steps-4,
.steps-5 {
  animation-name: pop;
  animation-duration: 0.6s; /* Adjust the duration as needed */
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

@keyframes pop {
  0% {
    transform: scale(0.8); /* Start smaller */
    opacity: 0; /* Start invisible */
  }
  50% {
    transform: scale(1.1); /* Scale up slightly */
    opacity: 1; /* Become visible */
  }
  100% {
    transform: scale(1); /* End at original size */
  }
}

.infoicon {
  position: absolute;
  cursor: pointer;
  color: #6c757d;
  height: 24px;
  width: 24px;
  transform: translateX(-130%) translateY(-130%);
}

@media (max-width: 768px) { /* Adjust the breakpoint as necessary */
  .infoicon {
    position: absolute;
    cursor: pointer;
    color: #6c757d;
    height: 24px;
    width: 24px;
    transform: translateY(-130%);
    left: 90%
  }
}

.popover-arrow {
  display: none !important;
}