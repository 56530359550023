.btn-brand {
  color: $white;
  border-color: $primary;
  background-color: $primary;
  border-color: $primary;
  &:hover,
  &:active,
  &:focus {
    background-color: $primary;
    color: $white;
    border-color: $primary;
    box-shadow: 0 1rem 1rem rgba($primary, 0.15) !important;
  }
  &:disabled {
    background-color: $primary-disabled;
    color: $black;
    border-color: $primary-disabled;
    box-shadow: 0 1rem 1rem rgba($primary-disabled, 0.15) !important;
  }
}
.btn-secondary {
  color: $black;
  background-color: $secondary;
  border-color: $secondary;
  &:hover,
  &:active,
  &:focus {
    color: $black;
    background-color: $secondary;
    border-color: $secondary;
    box-shadow: 0 1rem 1rem rgba(#ffe083, 0.15) !important;
  }
}
.btn-outline-brand {
  color: $primary !important;
  border-color: $primary !important;
  border-color: $primary !important;
  &:hover,
  &:active,
  &:focus {
    background-color: $primary !important;
    color: $white !important;
  }
}

.btn-toggle-brand {
  color: $primary !important;
  background-color: $white !important;
  border-color: $primary !important;

  &:disabled {
    color: $primary !important;
    background-color: $white !important;
    border-color: $primary !important;
  }

  &:hover,
  &.active {
    color: $white !important;
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.btn-outline-light {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  &:hover,
  &:active,
  &:focus {
    background-color: rgba(0, 0, 0, 0.5);
    color: $white;
  }
}
.btn-check:checked + .btn-outline-brand {
  background-color: $primary !important;
  color: $white !important;
}

.btn-none {
  padding: 0 !important;
  box-shadow: none !important;
  &:hover,
  &:active,
  &:focus {
    box-shadow: none !important;
    padding: 0 !important;
    background-color: transparent !important;
    color: $black !important;
    border: 0 !important;
  }
}
