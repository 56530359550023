@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

h2.signin {
	margin-top: 2rem;
}

.App {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.curved {
  border-radius: 2%;
}

/* .row {
  text-align: center;
} */

.homebutton {
  margin: 5px;
  margin-top: 10px;
}

.inputbuttoncss {
  justify-content: center;
  display: flex;
  --ReactInputVerificationCode-itemWidth: 40px;
  --ReactInputVerificationCode-itemHeight: 48px;
  --ReactInputVerificationCode-itemSpacing: 8px;
}

.verbutton {
  margin: 5px;
  border-radius: 2%;
}

.errText {
  font-size: 20px;
  margin: 4px;
}

.nomargins {
  margin: 0;
  padding: 0;
}

.subscribebutton {
  margin: 5px;
  margin-top: 10px;
}

.inline {
  display: inline-block !important;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-one {
  flex: 1;
  margin: 5px;
  margin-top: 10px;
}

.tnr {
  font-family: "Times New Roman";
}

.react-tel-input .form-control {
  width: 100% !important; /* Adjust as needed */
  font-family: "Poppins";
}

.input-style {
  font-size: 15px;
  margin-Bottom: 2px;
}