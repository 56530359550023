.avatar_initials {
  /* Center the content */
  display: inline-block;
  vertical-align: middle;

  /* Used to position the content */
  position: relative;

  /* Colors */
  background-color: #e8f6fe;
  color: $primary;

  /* Rounded border */
  border-radius: 50%;
  height: 2rem;
  width: 2rem;

  .avatar__letters {
    /* Center the content */
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
