@import '_variables';
@import '_text';
@import '_background';
@import '_form';
@import '_hero';
@import '_buttons';
@import '_avatar';
@import '_bottomnav';

//Pages
@import '_home';
@import '_registration';
@import '_timeline';
@import '_patient';

a {
  text-decoration: none !important;
  &.active {
    color: $primary !important;
    font-weight: 600 !important;
  }
  &:hover {
    color: $primary !important;
  }
}
.resize-none {
  resize: none;
}

//Z-index
.zi-2 {
  z-index: 2 !important;
}
.zi-n1 {
  z-index: -1 !important;
}
.zi-sticky {
  z-index: $zindex-sticky;
}

//position
@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute !important;
  }
}

.top-8 {
  top: 8%;
}

//Margin
.mt-10 {
  margin-top: 10px;
}
.mt-58 {
  margin-top: 58px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-115 {
  margin-top: 115px;
}

.mt-n5 {
  margin-top: -3rem !important;
}

//padding
.pr-2 {
  padding-right: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}

//height
.h-6 {
  height: 1.5rem;
}
.h-50px {
  height: 50px !important;
}

//width
.w-6 {
  width: 1.5rem;
}

//overflow
.overflow-hidden {
  overflow: hidden !important;
}

[class*='text-highlight-'] {
  background: left 1em/1em 0.2em;
  background-repeat: repeat-x;
}
.text-highlight-warning {
  background-image: linear-gradient(
    to bottom,
    rgba($secondary, 0.5),
    rgba($secondary, 0.5)
  );
}
.background-shade {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72%;
  background-color: $secondary-light;
  z-index: -1;
}
.how-it-works {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.testimonials {
  margin-bottom: 6rem;
  padding: 6rem 0;
  .testimonial-img {
    width: 64px;
    height: 64px;
  }
}
.auth-nav {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

//Form
.form-control {
  &:focus {
    box-shadow: none !important;
  }
}

//border radius
.radius-1 {
  border-radius: 1rem;
}

.slider {
  .slider-caption {
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 50%);
    h5 {
      padding-bottom: 20px;
    }
  }
}

.ml-5 {
  margin-left: 5rem !important;
}
​.mt-2 {
  margin-top: 2rem !important;
}
​.center {
  text-align: center;
}

.cursor {
  &:hover {
    cursor: pointer;
  }
}
#logo-img {
  height: 64px;
  width: 100px;
  padding: 0.5rem;
}