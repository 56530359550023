.hero-background {
  background-image: url('../assets/hero.jpg');
  background-size: 150rem;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
}

hr.solid { 
	border-top: 2px solid #999;
}

.white-background {
 background-color: #FAF8F6;
 background-size: 150rem;
 background-repeat: no-repeat;
 background-position: bottom;
 position: absolute;
 bottom: 0;
 top: 0;
 right: 0;
 left: 0;
  }
