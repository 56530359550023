.gradient-x-three-sm-primary {
  background-image: linear-gradient(
    to right,
    rgba(55, 125, 255, 0.03),
    rgba(9, 165, 190, 0.05) 50%,
    rgba(245, 202, 153, 0.05)
  );
}
.content-space-b-1 {
  padding-bottom: 3rem !important;
}
.ms-n7 {
  margin-left: -3rem !important;
}
.me-n7 {
  margin-right: -3rem !important;
}
.mt-n6 {
  margin-top: -2.5rem !important;
}
.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.me-n10 {
  margin-right: -4.5rem !important;
}

.shape {
  background: url('../assets/shape.svg') center no-repeat;
}

.get-started {
  padding-left: 5rem;
  padding-right: 5rem;
}
.sentence {
  .slidingVertical {
    span {
      animation: topToBottom 10s linear infinite 0s;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      top: 3.03rem;
      left: 8.12rem;
      width: 100%;
      color: $primary;
      &:nth-child(2) {
        animation-delay: 2.5s;
      }
      &:nth-child(3) {
        animation-delay: 5s;
      }
      &:nth-child(4) {
        animation-delay: 7.5s;
      }
    }
  }
}

@media (max-width: 767px) {
  .me-0 {
    margin-right: 0rem !important;
  }
}
@media (min-width: 768px) {
  .content-space-b-md-2 {
    padding-bottom: 5rem !important;
  }
}

@media (min-width: 992px) {
  .content-space-t-lg-3 {
    padding-top: 6.5rem !important;
  }
  .content-space-b-lg-3 {
    padding-bottom: 4.5rem !important;
  }
}

//steps
.steps-list {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  max-width: inherit;

  .steps-number {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: $secondary;
    border-radius: 50%;
    color: $black;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 1.375rem;
    font-weight: 900;
    height: 2.5rem;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1;
    margin-bottom: 2.5rem;
    width: 2.5rem;
  }
  .steps-line--1 {
    margin-left: 0.5rem;
    margin-top: -0.5rem;
  }
  .steps-line--2 {
    margin-left: 0.5rem;
    margin-top: 1.5rem;
  }
  .steps-line--3 {
    margin-left: -0.5rem;
    margin-top: -1rem;
  }
}
@media screen and (max-width: 47.99875em) {
  .sentence {
    position: relative;
    .slidingVertical span {
      left: 5.12rem;
      top: 2.1rem;
      width: 15rem;
    }
  }
  .get-started {
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
  }
  .steps-list {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-gap: 1rem;
    .steps-item {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 18.8125rem;
      .steps-number-and-line {
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 1rem;
        .steps-number {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
@media print, screen and (min-width: 60em) {
  .steps-list {
    grid-gap: 1rem;
    .steps-line--1 {
      margin-left: 1rem;
      margin-top: -1rem;
    }
    .steps-line--2 {
      margin-left: 1rem;
      margin-top: 2rem;
    }
  }
}

@keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(50px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    transform: translateY(-50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
