.mobile-nav {
  background: $primary;
  position: fixed;
  bottom: 0;
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  .bloc-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span,
    svg {
      color: $white;
    }
    &.active {
      span,
      svg {
        color: $black !important;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .mobile-nav {
    display: none;
  }
}
